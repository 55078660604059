import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _router = inject(Router);
  private poolData: any;
  private userPool: any;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor() {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }
  /**
   * @description for auto refresh after expiration of current token.
   * @param token is a CognitoRefreshToken
   * @returns new CognitoUserSession
   */
  refreshToken(token: CognitoRefreshToken): Observable<any> {
    this.poolData = {
      UserPoolId: environment.CognitoUserPoolId,
      ClientId: environment.CognitoClientId,
    };
    const data: any = localStorage.getItem('authenticatedUser');
    this.userPool = new CognitoUserPool(this.poolData);
    let userData = {
      Username: JSON.parse(data).username,
      Pool: this.userPool,
    };
    let user = new CognitoUser(userData);
    return from(
      new Promise((resolve, reject) => {
        user?.refreshSession(
          token,
          (err: Error, session: CognitoUserSession) => {
            if (err) {
              reject(err);
            } else if (!session.isValid()) {
              resolve(null);
            } else {
              resolve(session);
            }
          }
        );
      })
    );
  }

  /**
   * Change Password
   * @param oldPassword string
   * @param newPassword string
   * @returns any
   */
  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    this.poolData = {
      UserPoolId: environment.CognitoUserPoolId,
      ClientId: environment.CognitoClientId,
    };
    const data: any = localStorage.getItem('authenticatedUser');
    this.userPool = new CognitoUserPool(this.poolData);
    let userData = {
      Username: JSON.parse(data)['cognito:username'],
      Pool: this.userPool,
    };
    let user = new CognitoUser(userData);

    const authDetails = new AuthenticationDetails({
      Username: JSON.parse(data).email,
      Password: oldPassword,
    });

    return from(
      new Promise((resolve, reject) => {
        user.authenticateUser(authDetails, {
          onSuccess: () => {
            user?.changePassword(
              oldPassword,
              newPassword,
              (err: any, session: any) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(session);
                }
              }
            );
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      })
    );
  }

  _setToken(token: string): void {
    localStorage.setItem('accessToken', JSON.stringify(token));
  }

  _getToken() {
    if (typeof localStorage !== 'undefined') {
      const token: any = localStorage.getItem('accessToken');
      return JSON.parse(token);
    }
  }

  _setAuthenticatedUser(authenticatedUser: any): void {
    localStorage.setItem(
      'authenticatedUser',
      JSON.stringify(authenticatedUser)
    );
  }

  _getAuthenticatedUser() {
    if (typeof localStorage !== 'undefined') {
      const user: any = localStorage.getItem('authenticatedUser');
      return JSON.parse(user);
    }
  }

  logOut(): void {
    if (typeof localStorage !== 'undefined') {
      localStorage.clear();
    }
    this._router.navigate(['/']);
  }

  // Start the loading process
  start(): void {
    this.isLoadingSubject.next(true);
  }
  // Stop the loading process
  stop(): void {
    this.isLoadingSubject.next(false);
  }

  // Route to
  routeTo(path: string): void {
    this._router.navigate([path]);
  }
}
