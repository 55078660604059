export const environment = {
  apiBaseUrl: 'https://api.screen360app.tv',
  CognitoUserPoolId: 'us-east-2_WAdM1iUUr',
  CognitoClientId: '1o6no7dkhpr6vp39qqv662ocb1',
  googleMapsApiKey: 'AIzaSyB_c8YgV9x7I_vMflWC9NhtKXli_UHnxa4',
  googleTimeZoneBase: 'https://maps.googleapis.com/maps/api/timezone/json',
  preScreening:
    'https://docs.google.com/forms/d/1PdB4VzFQS9apMeRbEFd8dn4R8h48jafK0GIi1YyuYO4/edit?pli=1',
  postScreening:
    'https://docs.google.com/forms/d/12ViHVTyjFDF8-Zj83ztqAfGFFqz5FM5jkqnY0tVzEUU/edit',
  recollector:
    'https://docs.google.com/forms/d/1AglK_nu1htppI9U9gJmMmJouYANi7t2Hi9iUiHxq4SU/edit',
};
